$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");

            } else {
                $(this).removeClass("animateIn--active");
            }
           
        });

        // countIn
        $(".countIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                $(".js-odometer").each(function(){
                    var value = $(this).data("odometer");
                    $(this).text(value);
                });
            } 
        })
    }



    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function () {
        $(this).toggleClass("active");
        $(".js-nav").toggleClass("active")
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * heroSlider
    // *
    // *
    new Swiper('.js-hero-slider', {
        loop: true,
        speed: 1200,
        watchOverflow: true,
        slidePerView: 1,
        autoplay: {
            delay: 8000,
        },
        parallax: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * contentSlider
    // *
    // *
    var $contentSlider = $(".js-content-slider");

    if ($contentSlider.length) {
        $contentSlider.each(function () {
            $(this).find(".js-content-slider-track").slick({
                fade: false,
                infinite: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 2500,
                speed: 1000,
                waitForAnimate: true,
                dots: true,
                pauseOnHover: false,
                appendDots: $(this).find(".js-content-slider-dots"),
                prevArrow: $(this).find(".js-content-slider-prev"),
                nextArrow: $(this).find(".js-content-slider-next"),
                rows: 0,
            });

            $(this).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
                var i = (currentSlide ? currentSlide : 0) + 1;
                $(this).find(".js-content-slider-count").text(i);
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-header").click(function () {
        $(this).parents(".js-accordion-item").toggleClass("active").siblings(".js-accordion-item").removeClass("active");
        $(this).parents(".js-accordion-item").find(".js-accordion-content").slideToggle();

        // $(this).parents(".js-accordion-item").siblings().removeClass("active").find(".js-accordion-content").slideUp();
        // $(this).siblings(".js-accordion-content").slideToggle();
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * countup
    // *
    // *

    var $countUp = $(".js-countup");
    if ($countUp.length) {
        $countUp.each(function () {
            var element = $(this).get(0);
            var endValue = parseFloat($(this).text()); // Parse as float to handle both integers and decimals
            var options = {
                startVal: 0,
                enableScrollSpy: true, // Enable scroll spy functionality
                scrollSpyOnce: true   // Ensures it animates only once on view
            };

            // Check if the value is an integer or float and set decimal places accordingly
            if (!Number.isInteger(endValue)) {
                options.decimalPlaces = 1; // Float, 1 decimal place
            }

            const countUp = new CountUp(element, endValue, options);
            if (!countUp.error) {
                countUp.handleScroll(); // Ensure scrollSpy works by calling handleScroll for this instance
            } else {
                console.error(countUp.error);
            }
        });
    }
    // * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    // * dialog window
    // *
    // *
    var $dialog = $(".js-dialog"),
        dialogState = sessionStorage.getItem('dialogState');

    $(".js-close-dialog").click(function () {
        $dialog.addClass("close");
        sessionStorage.setItem('dialogState', true);
        $(this).parents(".js-dialog").get(0).close();
        $("body").removeClass("freeze");
    });

    // backdrop click
    $(".js-dialog").click(function (e) {
        if (e.target === e.currentTarget) {
            $dialog.addClass("close");
            sessionStorage.setItem('dialogState', true);
            $(this).get(0).close();
            $("body").removeClass("freeze");
        }
    });

    if (!dialogState && $dialog.length > 0) {
        $dialog.get(0).showModal();
        $("body").addClass("freeze");
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * backgroundSLider
    // *
    // *
    var $bgSlider = $(".js-backgroundslider");

    if ($bgSlider.length) {
        $bgSlider.each(function () {
            $(this).find(".js-backgroundslider-track").slick({
                fade: true,
                infinite: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 8000,
                speed: 1200,
                waitForAnimate: true,
                dots: true,
                pauseOnHover: false,
                appendDots: $(this).find(".js-backgroundslider-dots"),
                prevArrow: $(this).find(".js-backgroundslider-prev"),
                nextArrow: $(this).find(".js-backgroundslider-next"),
                rows: 0,
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * imageslider
    // *
    // *
    var $imageslider = $(".js-imageslider");

    if ($imageslider.length) {
        $imageslider.each(function () {
            $(this).find(".js-imageslider-track").slick({
                fade: true,
                infinite: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 8000,
                speed: 1200,
                waitForAnimate: true,
                dots: true,
                pauseOnHover: false,
                appendDots: $(this).find(".js-imageslider-dots"),
                prevArrow: $(this).find(".js-imageslider-prev"),
                nextArrow: $(this).find(".js-imageslider-next"),
                rows: 0,
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teaserCarousel
    // *
    // *
    var $teaserCarousel = $(".js-teasercarousel");

    if ($teaserCarousel.length) {
        $teaserCarousel.each(function () {
            $(this).find(".js-teasercarousel-track").slick({
                fade: false,
                infinite: true,
                arrows: true,
                autoplay: false,
                autoplaySpeed: 8000,
                speed: 1200,
                waitForAnimate: true,
                dots: true,
                pauseOnHover: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                appendDots: $(this).find(".js-teasercarousel-dots"),
                prevArrow: $(this).find(".js-teasercarousel-prev"),
                nextArrow: $(this).find(".js-teasercarousel-next"),
                rows: 0,
                responsive: [
                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * show more
    // *
    // *
    $(".js-showmore-button").click(function () {
        $(this).toggleClass("active");
        $(".js-showmore").slideToggle();
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderModel
    // *
    // *
    var $sliderModel = $(".js-slidermodel");

    if ($sliderModel.length) {
        $sliderModel.each(function () {
            $(this).slick({
                fade: false,
                infinite: true,
                arrows: true,
                autoplay: false,
                speed: 800,
                waitForAnimate: true,
                dots: false,
                pauseOnHover: false,
                prevArrow: $(this).find(".js-slidermodel-prev"),
                nextArrow: $(this).find(".js-slidermodel-next"),
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * carouselModel
    // *
    // *
    var $carouselModel = $(".js-carouselmodel");

    if ($carouselModel.length) {
        $carouselModel.each(function () {
            $(this).find(".js-carouselmodel-track").slick({
                fade: false,
                infinite: true,
                arrows: true,
                autoplay: false,
                autoplaySpeed: 8000,
                speed: 1200,
                waitForAnimate: true,
                dots: true,
                pauseOnHover: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                appendDots: $(this).find(".js-carouselmodel-dots"),
                prevArrow: $(this).find(".js-carouselmodel-prev"),
                nextArrow: $(this).find(".js-carouselmodel-next"),
                rows: 0,
                responsive: [
                    {
                        breakpoint: 1900,
                        settings: {
                            slidesToShow: 1,
                            variableWidth: true
                        }
                    },
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 1,
                            variableWidth: false
                        }
                    }
                ]
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teasergrid slidedown
    // *
    // *
    $(".js-teasergrid").click(function () {
        $(this).toggleClass("active");
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * quoteClip
    // *
    // *
    gsap.registerPlugin(ScrollTrigger);

    var $quoteClip = $(".js-quoteclip");

    $quoteClip.each(function () {
        var $trigger = $(this);
        var words = gsap.utils.toArray('.js-quoteclip-word');
    
        // create GSAP timeline
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: $trigger,
                scrub: true,
                pin: false,
                markers: false,
                start: "top center",
                end: "bottom center" 
            }
        });
    
        // loop through each word in the array during timeline
        words.forEach((word, i) => {
            tl.fromTo(word, { 
                    clipPath: "inset(0% 100% 0% 0%)" 
                }, { 
                    clipPath: "inset(0% 0% 0% 0%)",
                }
            )

        }); 
        words.forEach((word, i) => {
            tl.to(word, { 
                    clipPath: "inset(0% 0% 0% 100%)" 
                }, ((words.length - i) * 0.1) + i + 2
            )
            console.log(words.length)
        });  
    });


});


// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky PageNav
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= ($(window).outerHeight() - 80)) {
        $(".js-pagenav").addClass("sticky");
    } else {
        $(".js-pagenav").removeClass("sticky");
    }
});


// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= 40) {
        $(".js-header").addClass("sticky");
    } else {
        $(".js-header").removeClass("sticky");
    }
});
